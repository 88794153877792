import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import { Flex, Box, List, ListItem, ListIcon } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { BsTools } from 'react-icons/bs';
import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import CallToAction from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Wrapper style={{
      textAlign: 'justify'
    }} width={960} mdxType="Wrapper">
      <hr></hr>
  <Heading icon={<BsTools mdxType="BsTools" />} mdxType="Heading">Service</Heading>
  <Flex flexWrap={'wrap'} justifyContent='space-between' mdxType="Flex">
    <Box width={['100%', '100%', '50%', '50%']} p={'6'} mdxType="Box">
      <h3>Uhren Service</h3>
      <List mdxType="List">
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Mechanische Uhren / Handaufzug</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Quarzarmbanduhren</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Automatikuhren</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Batteriewechsel</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Komplette Revisionen</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Glasersatz</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Leder-, Metall-, Kuststoffuhrarmbänder</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Bandanpassung</ListItem>
      </List>
    </Box>
    <Box width={['100%', '100%', '50%', '50%']} p={'6'} mdxType="Box">
      <h3>Goldschmiede Service</h3>
      <List mdxType="List">
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Goldschmiedereparaturen aller Art</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Kette und Ösen zulöten, Verschlüsse ersetzen</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Ringgrößen ändern</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Perlenketten neu fassen oder knoten</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Trauringumarbeitung</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Neuanfertigungen</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Schmuck reinigen und aufarbeiten</ListItem>
      </List>
    </Box>
  </Flex>
      <hr></hr>
    </Wrapper>
    <Wrapper mdxType="Wrapper">
  <CallToAction mdxType="CallToAction" />
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      